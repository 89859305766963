@font-face {
  font-family: "Ruberoid-Thin";
  src: url(./fonts/Ruberoid-Thin.ttf);
}

@font-face {
  font-family: "Ruberoid-Light";
  src: url(./fonts/Ruberoid-Light.ttf);
}

@font-face {
  font-family: "Ruberoid-Medium";
  src: url(./fonts/Ruberoid-Medium.ttf);
}

@font-face {
  font-family: "Ruberoid-Regular";
  src: url(./fonts/Ruberoid-Regular.ttf);
}

@font-face {
  font-family: "Ruberoid-Bold";
  src: url(./fonts/Ruberoid-Bold.ttf);
}

:root {
  --our-dark-color: #37393E;
  --our-white-color: #FFF;
  --our-bordo-color: #801228;
}


html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Ruberoid-Regular";
}

.App-header {

  height: 100px;
  top: 0px;
  display: flex;
  align-items: center;
  background-color: var(--our-dark-color);
  padding-left: 40px;
  justify-content: space-between;
  padding-right: 70px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;

  .contacts {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    justify-content: center;
    gap: 0px;

    .tel-social {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }

  a {
    color: white;
  }
}

.content {
  margin-top: 100px;

  section {
    max-width: 800px;
    margin: auto;

    p {
      text-indent: 10px;
    }
  }

  .carousel {
    .legend2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 20px;
      color: white;
      background-color: #0e0e0ea9;
      margin-bottom: 0;
    }
  }

  .services-list {
    li {
      margin-bottom: 20px;
    }
  }

  .cta-btn {
    width: 330px;
    margin: auto;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #128c7e;
    color: white;
    font-size: 20px;
    border-radius: 45px;
    transition: all .3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #075e54;

    }
  }

  .licence {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.carousel .control-next.control-arrow:before {
  border-left-color: var(--our-bordo-color) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right-color: var(--our-bordo-color) !important;
}


.divider {
  height: 7px;
  width: 90%;
  margin: auto;
  background-color: var(--our-bordo-color);
}

a {
  color: black
}

footer {
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--our-dark-color);

  .footer-data {
    color: white;
    width: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    h3,
    h4 {
      margin: 0;
    }

    .contacts {

      display: flex;
      align-items: center;
      gap: 10px;

      a {
        color: white;
      }
    }
  }

  .footer-map {
    width: 50%;

  }
}


@media only screen and (max-width: 600px) {
  .App-header {

    height: 110px;
    display: flex;
    padding-left: 20px;
    align-items: center;

    .contacts {
      display: none;
    }
  }

  .content {
    margin-top: 110px;

    .carousel {
      .legend2 {
        font-size: 12px;
        padding: 10px;
      }
    }
  }

  footer {
    height: 300px;
    display: flex;

    flex-direction: column;

    .footer-data {

      width: 100%;


    }

    .footer-map {
      width: 100%;

    }
  }

}